import { createSlice } from '@reduxjs/toolkit'
import { authApi } from '../../api'
// Slice

const slice = createSlice({
  name: 'products',
  initialState: {
    products: {},
  },
  reducers: {
    getProductsSuccess: (state, action) => {
      state.products = action.payload;
    },
  },
});

export default slice.reducer

// Actions

const { getProductsSuccess } = slice.actions

export const getProducts = () => async dispatch => {

  try {
    const returnData = await authApi.get('/accounts')
    console.log(returnData);
    dispatch(getProductsSuccess(returnData.data));
  } catch (e) {
    return console.error(e.message);
  }
}