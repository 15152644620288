import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { Router as BrowserRouter, Route, Switch } from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import PrivateRoute from "./shared/components/PrivateRoute";
import AuthenticatedRoute from "./shared/components/AuthenticatedRoute";
import { useDispatch } from "react-redux";
import { getRefreshToken } from './store/slices/authSlice'
import { getCourses } from "./store/slices/coursesSlice";
import { global } from "./global";
import history from "./history";
import Updater from "./shared/components/Updater";

console.log('REACT_APP_STAGE:', process.env.REACT_APP_STAGE);

const Admin = lazy(() => import('./admin/Main'));

const Website = lazy(() => import('./website/Main'));

const App = () => {
  const dispatch = useDispatch();

  // On app refresh - attempt to get a new JWT if user was logged in
  useEffect(() => {
    async function refreshToken() {
      // You can await here
      const loggedIn = localStorage.getItem('loggedIn');
      if (loggedIn) {
        await dispatch(getRefreshToken());
      };
      await dispatch(getCourses());
    }
    refreshToken();
  }, [dispatch]);

  return (
    <BrowserRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Pace color={theme.palette.primary.light} />
        <Suspense fallback={<Fragment />}>
          <Updater />
          <Switch>
            <AuthenticatedRoute path='/admin'>
              <PrivateRoute path='/admin' roles={[global.constants.ADMIN_ROLE]}>
                <Admin />
              </PrivateRoute>
            </AuthenticatedRoute>
            <Route>
              <Website />
            </Route>
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default App;
