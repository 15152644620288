import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import authReducer from './slices/authSlice';
import productsReducer from './slices/productsSlice';
import usersReducer from './slices/usersSlice';
import coursesReducer from './slices/coursesSlice';
import eventsReducer from './slices/eventsSlice';
import regionsReducer from './slices/regionsSlice';
import contactsReducer from './slices/contactsSlice';
import notificationsReducer from './slices/notificationsSlice';

const reducer = combineReducers({
  auth: authReducer,
  products: productsReducer,
  users: usersReducer,
  courses: coursesReducer,
  events: eventsReducer,
  regions: regionsReducer,
  contacts: contactsReducer,
  notifications: notificationsReducer,
})

export const store = configureStore({
  reducer,
});

