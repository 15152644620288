import { createSlice } from '@reduxjs/toolkit'
import { api, authApi } from '../../api'
import history from "../../history";
// Slice

const slice = createSlice({
  name: 'auth',
  initialState: {
    error: false,
    loggedIn: false,
    isloading: false,
    user: {},
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.loggedIn = true;
      state.isLoading = false;
    },
    refreshToken: (state, action) => {
      state.user = action.payload;
      state.loggedIn = true;
    },
    logoutSuccess: (state, action) => {
      state.user = {};
      state.isLoading = false;
      state.loggedIn = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    isLoading: (state) => {
      state.isLoading = true;
    },
  },
});

export default slice.reducer

// Actions

const { loginSuccess, logoutSuccess, refreshToken, hasError, isLoading } = slice.actions

export const login = (data, closeModal) => async dispatch => {

  try {

    dispatch(isLoading());
    const returnData = await api.post('/accounts/authenticate', data)
    localStorage.setItem("loggedIn", true);
    dispatch(loginSuccess(returnData.data));

    closeModal();
    returnData.data.role === 'Admin' ? history.push("/admin") : history.push("/user-profile");
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
}

export const getRefreshToken = () => async dispatch => {

  try {
    const returnData = await api.post('/accounts/refresh-token')
    localStorage.setItem("loggedIn", true);
    dispatch(refreshToken(returnData.data));
  } catch (e) {
    return console.error(e.message);
  }
}

export const logout = () => async dispatch => {
  try {
    dispatch(isLoading());
    await authApi.post('/accounts/revoke-token');
    localStorage.removeItem("loggedIn");
    dispatch(logoutSuccess());
    history.push("/");

  } catch (e) {
    console.error(e.message);
    // Even if error - Logout anyway.
    localStorage.removeItem("loggedIn");
    dispatch(logoutSuccess());
    history.push("/");
  }
}