const variables = {
  devApiUrl: 'http://localhost:4000/api/v1',
};

const constants = {
  ADMIN_ROLE: 'Admin',
  USER_ROLE: 'User',
  GOOGLE_API_KEY: 'AIzaSyBpBnRIFNZ2mZtr6NOW4we2wmzYT60w5q0',
  BSGC_LAST_NAME: 'bsgcUserName',
  BSGC_CARD_NUMBER: 'bsgcCardNumber',
  ALL_REGION_DEFAULT_TEXT: 'All Regions',
};

const actions = {
  post: post,
};

const timezone = 'America/New_York';

function post(body) {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
}

export const global = {
  variables,
  actions,
  constants,
  timezone,
};
