import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api';
import history from '../../history';
// Slice

const slice = createSlice({
  name: 'contacts',
  initialState: {
    error: '',
    isLoading: false,
    signupSuccess: false,
    contacts: {},
    contact: {},
  },
  reducers: {
    eNewsSignupSuccess: (state, action) => {
      state.signupSuccess = true;
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    isLoading: (state) => {
      state.isLoading = true;
    },
  },
});

export default slice.reducer;

// Actions

const { eNewsSignupSuccess, isLoading, hasError } = slice.actions;

export const subscribe = (data) => async (dispatch) => {
  try {
    dispatch(isLoading());
    const resp = await api.post('/contacts', data);
    dispatch(eNewsSignupSuccess(resp.data));
    history.push('/thank-you');
  } catch (e) {
    if (e.response) {
      const { message } = e.response.data;
      return dispatch(hasError(message));
    } else {
      // The request was made but no response was received
      // `e.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in Node.js
      console.error(e.request);
      return dispatch(hasError('An error occurred while processing your request.'));
    }
  }
};
