import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/index';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     console.log("Service Worker Update Code Executed.")
//     onServiceWorkerUpdate(registration);
//   }
// });
