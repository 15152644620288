import axios from 'axios';
import { store } from '../store';
import { getRefreshToken } from '../store/slices/authSlice';

const baseUrl =
  process.env.REACT_APP_STAGE === 'production'
    ? 'https://prd-bsgc-services.bigsummergolf.com/v1'
    : process.env.REACT_APP_STAGE === 'test'
    ? 'https://tst-services.bigsummergolf.com/v1'
    : 'http://localhost:4000/v1';

const defaultOptions = {
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

// API instance for all auth end points
export const authApi = axios.create(defaultOptions);

// Set the AUTH token for any request
authApi.interceptors.request.use(function (config) {
  const state = store.getState();
  const token = state.auth.user.jwtToken;
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

authApi.interceptors.response.use(
  (response) => {
    // Return normal response
    return response;
  },
  (error) => {
    // Return non auth error
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    // 401 auth error - dispatch refresh token to get new JWT and try orginal request again
    return store
      .dispatch(getRefreshToken())
      .then(() => {
        // Make new request
        const config = error.config;
        const state = store.getState();
        const token = state.auth.user.jwtToken;
        config.headers.Authorization = token ? `Bearer ${token}` : '';

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
);

// API instance for all non-auth end points
export const api = axios.create(defaultOptions);
