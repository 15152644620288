import React, { lazy } from "react"
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

const LoggedOutComponent = lazy(() => import("../../website/Main"));

export default function PrivateRoute({ roles, children }) {

  const { auth } = useSelector((state) => state)

  const authed = roles.includes(auth?.user?.role || '');

  if (!authed) { return <Route><LoggedOutComponent /></Route> }

  return (<Route>{children}</Route>)
};
