import { withServiceWorkerUpdater } from '@3m1/service-worker-updater';
import { Button } from "@material-ui/core"

const Updater = (props) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props;
  return newServiceWorkerDetected ? (
    <>
      New version of site available!
      <Button
        variant="contained"
        color="secondary"
        onClick={onLoadNewServiceWorkerAccept}
      >Click Here To Update!</Button>
    </>
  ) : null; // If no update is available, render nothing
}

export default withServiceWorkerUpdater(Updater);