import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'

// Slice

const slice = createSlice({
  name: 'regions',
  initialState: {
    regions: {},
    error: false,
    isLoading: false,
  },
  reducers: {
    getRegionsSuccess: (state, action) => {
      state.regions = action.payload;
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    isLoading: (state) => {
      state.isLoading = true;
    },
  },
});

export default slice.reducer

// Actions

const {
  hasError,
  isLoading,
  getRegionsSuccess,
} = slice.actions;

export const getRegions = () => async dispatch => {

  try {
    dispatch(isLoading());
    const returnData = await api.get('/regions')
    dispatch(getRegionsSuccess(returnData.data));
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message));
  }
};