import { createSlice } from '@reduxjs/toolkit'
import { api, authApi } from '../../api'
import _ from 'lodash'

// Slice

const slice = createSlice({
  name: 'events',
  initialState: {
    events: {},
    error: false,
    isLoading: false,
  },
  reducers: {
    getEventsSuccess: (state, action) => {
      state.events = action.payload;
      state.isLoading = false;
    },
    createEventSuccess: (state, action) => {
      state.events.push(action.payload);
      state.isLoading = false;
    },
    editEventSuccess: (state, action) => {
      const newEvents = _.map(state.events, (event) => {
        return event.id === action.payload.id ? action.payload : event;
      });
      state.events = newEvents;
      state.isLoading = false;
    },
    deleteEventSuccess: (state, action) => {
      const newEvents = _.filter(state.events, (event) => {
        return event.id !== action.payload
      });
      state.events = newEvents;
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    isLoading: (state) => {
      state.isLoading = true;
    },
  },
});

export default slice.reducer

// Actions

const {
  hasError,
  isLoading,
  getEventsSuccess,
  createEventSuccess,
  editEventSuccess,
  deleteEventSuccess
} = slice.actions;

export const getEvents = () => async dispatch => {

  try {
    dispatch(isLoading());
    const returnData = await api.get('/events')
    dispatch(getEventsSuccess(returnData.data));
  } catch (e) {
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
}

export const createEvent = (data, closeDialog) => async dispatch => {

  try {
    dispatch(isLoading());
    const returnData = await authApi.post('/events', data)
    dispatch(createEventSuccess(returnData.data));
    closeDialog();
  } catch (e) {
    console.log(e);
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
}

export const editEvent = (data, closeDialog, id) => async dispatch => {

  try {
    dispatch(isLoading());
    const returnData = await authApi.put(`/events/${id}`, data)
    dispatch(editEventSuccess(returnData.data));
    closeDialog();
  } catch (e) {
    console.log(e);
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
}

export const deleteEvent = (closeDialog, id) => async dispatch => {

  try {
    dispatch(isLoading());
    await authApi.delete(`/events/${id}`)
    dispatch(deleteEventSuccess(id));
    closeDialog();
  } catch (e) {
    console.log(e);
    const { request, ...errorObject } = e.response;
    return dispatch(hasError(errorObject.data.message))
  }
}